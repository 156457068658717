import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { OrderSlice, OrderFilters } from "./ordersTypes.ts";

const initialState: OrderSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: { type: "" },
  sort: {
    key: "requestDate",
    order: "desc",
  },
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<OrderSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<OrderSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [
          filterType: keyof Omit<OrderFilters, "type">,
          filterValue: string | undefined,
        ]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<OrderSlice["sort"]>) => {
      state.sort = action.payload;
    },
    setOrderType: (state, action: PayloadAction<OrderFilters["type"]>) => {
      state.filter.type = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort, setOrderType } =
  ordersSlice.actions;

export const selectPage = (state: RootState) => state.orders.page;
export const selectRowsPerPage = (state: RootState) => state.orders.rowsPerPage;
export const selectFilter = (state: RootState) => state.orders.filter;
export const selectSort = (state: RootState) => state.orders.sort;
export default ordersSlice.reducer;
